<template>
  <div>
    <div v-if="data.type === 'real_estate'" class="d-flex flex-wrap align-center">
      <div class="f18 font-weight-bold">
        {{ $t(`liquidityTypes.${data.type}`) }}
      </div>
      <v-divider vertical class="mx-6 primary"></v-divider>
      <div class="me-8">
        <span class="font-weight-bold me-2">{{ $t('input.land_number') }}:</span>
        <span>{{ data.land_number }}</span>
      </div>
      <div class="me-8">
        <span class="font-weight-bold me-2">{{ $t('input.block') }}:</span>
        <span>{{ data.block }}</span>
      </div>
      <div class="me-8">
        <span class="font-weight-bold me-2">{{ $t('input.district') }}:</span>
        <span>{{ data.district }}</span>
      </div>
      <div class="me-8">
        <span class="font-weight-bold me-2">{{ $t('input.city') }}:</span>
        <span>{{ data.city }}</span>
      </div>
      <div class="me-8">
        <span class="font-weight-bold me-2">{{ $t('input.deed_number') }}:</span>
        <span>{{ data.deed_number }}</span>
      </div>
      <div class="me-8">
        <span class="font-weight-bold me-2">{{ $t('input.deed_history') }}:</span>
        <span>{{ data.deed_history }}</span>
      </div>
      <div>
        <span class="font-weight-bold me-2">{{ $t('input.total_space') }}:</span>
        <span>{{ data.total_space }}</span>
      </div>
    </div>
    <div v-if="data.type === 'current_account'" class="d-flex flex-wrap align-center">
      <div class="f18 font-weight-bold">
        {{ $t(`liquidityTypes.${data.type}`) }}
      </div>
      <v-divider vertical class="mx-6 primary"></v-divider>
      <div class="me-8">
        <span class="font-weight-bold me-2">{{ $t('input.account_number') }}:</span>
        <span>{{ data.account_number }}</span>
      </div>
      <div class="me-8">
        <span class="font-weight-bold me-2">{{ $t('input.bank_name') }}:</span>
        <span>{{ data.bank_name }}</span>
      </div>
      <div>
        <span class="font-weight-bold me-2">{{ $t('input.ratio') }}:</span>
        <span>{{ data.ratio }}</span>
      </div>
    </div>
    <div v-if="data.type === 'gold'" class="d-flex flex-wrap align-center">
      <div class="f18 font-weight-bold">{{ $t(`liquidityTypes.${data.type}`) }}</div>
      <v-divider vertical class="mx-6 primary"></v-divider>
      <div class="me-8">
        <span class="font-weight-bold me-2">{{ $t('input.gold_karat') }}:</span>
        <span>{{ data.gold_karat }}</span>
      </div>
      <div class="me-8">
        <span class="font-weight-bold me-2">{{ $t('input.number_of_ounces') }}:</span>
        <span>{{ data.number_of_ounces }}</span>
      </div>
      <div>
        <span class="font-weight-bold me-2">{{ $t('input.weight_grams') }}:</span>
        <span>{{ data.weight_grams }}</span>
      </div>
    </div>
    <div v-if="data.type === 'silver'" class="d-flex flex-wrap align-center">
      <div class="f18 font-weight-bold">{{ $t(`liquidityTypes.${data.type}`) }}</div>
      <v-divider vertical class="mx-6 primary"></v-divider>
      <div>
        <span class="font-weight-bold me-2">{{ $t('input.weight_grams') }}:</span>
        <span>{{ data.weight_grams }}</span>
      </div>
    </div>
    <div v-if="data.type === 'company'" class="d-flex flex-wrap align-center">
      <div class="f18 font-weight-bold">{{ $t(`liquidityTypes.${data.type}`) }}</div>
      <v-divider vertical class="mx-6 primary"></v-divider>
      <div class="me-8">
        <span class="font-weight-bold me-2">{{ $t('input.company_name') }}:</span>
        <span>{{ data.company_name }}</span>
      </div>
      <div class="me-8">
        <span class="font-weight-bold me-2">{{ $t('input.cr_number') }}:</span>
        <span>{{ data.cr_number }}</span>
      </div>
      <div class="me-8">
        <span class="font-weight-bold me-2">{{ $t('input.capital') }}:</span>
        <span>{{ data.capital }}</span>
      </div>
      <div class="me-8">
        <span class="font-weight-bold me-2">{{ $t('input.company_hq') }}:</span>
        <span>{{ data.company_hq }}</span>
      </div>
      <div class="me-8">
        <span class="font-weight-bold me-2">{{ $t('input.activity') }}:</span>
        <span>{{ data.activity }}</span>
      </div>
      <div class="me-8">
        <span class="font-weight-bold me-2">{{ $t('input.deed_number') }}:</span>
        <span>{{ data.deed_number }}</span>
      </div>
      <div>
        <span class="font-weight-bold me-2">{{ $t('input.ratio') }}:</span>
        <span>{{ data.ratio }}</span>
      </div>
    </div>
    <div v-if="data.type === 'stock_portfolio'" class="d-flex flex-wrap align-center">
      <div class="f18 font-weight-bold">{{ $t(`liquidityTypes.${data.type}`) }}</div>
      <v-divider vertical class="mx-6 primary"></v-divider>
      <div class="me-8">
        <span class="font-weight-bold me-2">{{ $t('input.account_number') }}:</span>
        <span>{{ data.account_number }}</span>
      </div>
      <div class="me-8">
        <span class="font-weight-bold me-2">{{ $t('input.bank_name') }}:</span>
        <span>{{ data.bank_name }}</span>
      </div>
      <div>
        <span class="font-weight-bold me-2">{{ $t('input.ratio') }}:</span>
        <span>{{ data.ratio }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      endowment: (state) => state.endowment.endowment,
    }),
    data() {
      return this.endowment.liquidity || {};
    },
  },
  watch: {
    endowment(val) {},
  },
};
</script>
