<template>
  <div class="fourth">
    <v-card flat class="mt-8 rounded-xl px-7 pt-8 pb-16">
      <h1 class="text-center primary--text arabic-black f30">
        {{ $t('waqf.stepLast.title') }}
      </h1>
      <div class="mx-auto mt-6 black--text" style="max-width: 968px">
        <div class="d-flex justify-center pb-4 date-block">
          <div>
            <div class="font-weight-bold">{{ $t('waqf.step1')[0] }}:</div>
            <div class="mt-1">{{ $t('waqf.endowmentTypes')[data.type] }}</div>
          </div>
          <div class="mx-16">
            <div class="font-weight-bold">{{ $t('input.date') }}:</div>
            <div class="mt-1">
              <span v-if="data.document_date">{{ updatedAt }} AH</span>
            </div>
          </div>
          <div>
            <div class="font-weight-bold">{{ $t('input.todayDate') }}:</div>
            <div class="mt-1">
              {{
                new Date().toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })
              }}
              AD
            </div>
          </div>
        </div>
        <div class="primary--text text-h6 font-weight-bold mt-10">{{ $t('waqf.step1')[2] }}</div>
        <div class="mt-4 fild-text">{{ data.introduction_text }}</div>
        <div v-if="data.owner" class="primary--text text-h6 font-weight-bold mt-10">{{ $t('waqf.stepLast.ownerDetailes') }}</div>
        <v-card v-if="data.owner" flat class="radius16 pa-6 mt-4 d-flex flex-wrap align-center info-card" color="#f0f6f7" min-height="72">
          <div class="pe-3">
            <span class="font-weight-bold me-1">{{ $t('input.fullName') }}:</span>
            <span>{{ data.owner.full_name }}</span>
          </div>
          <div class="pe-3">
            <span class="font-weight-bold me-1">{{ $t('input.idNumber') }}:</span>
            <span>{{ data.owner.id_number }}</span>
          </div>
          <div class="pe-3">
            <span class="font-weight-bold me-1">{{ $t('input.birthday') }}:</span>
            <span>{{ data.owner.date_of_birth }}</span>
          </div>
        </v-card>
        <div class="primary--text text-h6 font-weight-bold mt-10">{{ $t('waqf.step1')[4] }}</div>
        <div class="my-4 font-weight-bold">{{ $t('waqf.stepLast.typeOfLiquiditydescription') }}:</div>
        <Liquidity />
        <div v-for="i in data.steps" :key="i.id">
          <div v-for="item in i.sections" :key="item.id">
            <div class="primary--text text-h6 font-weight-bold mt-10">{{ item.title }}</div>
            <div v-if="item.type == 'text'" class="mt-4 fild-text">{{ item.text }}</div>
            <div v-if="item.type == 'list'">
              <div class="mt-4 fild-text" v-if="item.text && i.text_enabled">{{ item.text }}</div>
              <div v-for="j in item.items" :key="j.key" class="mt-4 fild-text">{{ j.text }}</div>
            </div>
            <div v-if="item.type == 'people'">
              <v-card
                v-for="j in item.items"
                :key="j.key"
                flat
                class="radius16 pa-6 mt-4 d-flex flex-wrap align-center info-card"
                color="#f0f6f7"
                min-height="72"
              >
                <div class="pe-3">
                  <span class="font-weight-bold me-1">{{ $t('input.fullName') }}:</span>
                  <span>{{ j.full_name }}</span>
                </div>
                <div class="pe-3">
                  <span class="font-weight-bold me-1">{{ $t('input.idNumber') }}:</span>
                  <span>{{ j.id_number }}</span>
                </div>
                <div class="pe-3">
                  <span class="font-weight-bold me-1">{{ $t('input.birthday') }}:</span>
                  <span>{{ j.date_of_birth }}</span>
                </div>
              </v-card>
            </div>
            <div v-if="item.type == 'distributions'">
              <v-row :class="{ 'mt-7': !k }" v-for="(j, k) in item.items" :key="j.key">
                <v-col cols="3" v-if="!k">
                  <v-card flat class="radius16 pa-6 d-flex flex-wrap align-center font-weight-bold" color="#f0f6f7" min-height="72">
                    {{ $t('waqf.stepLast.beneficiary') }}
                  </v-card>
                </v-col>
                <v-col cols="9" v-if="!k">
                  <v-card flat class="radius16 pa-6 d-flex flex-wrap align-center font-weight-bold" color="#f0f6f7" min-height="72">
                    {{ $t('waqf.stepLast.details') }}
                  </v-card>
                </v-col>
                <v-col cols="3">
                  <div class="ps-8 pe-6">{{ j.beneficiary }}</div>
                </v-col>
                <v-col cols="9">
                  <div class="px-6">{{ j.details }}</div>
                </v-col>
                <v-col cols="12"><v-divider></v-divider></v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { formatHIjhri } from '@/helpers/years';
import Liquidity from '@/components/waqf/preview/Liquidity.vue';

export default {
  components: { Liquidity },
  data() {
    return {
      updatedAt: null,
    };
  },
  mounted() {
    setTimeout(() => {
      this.data.document_date ? (this.updatedAt = formatHIjhri(this.data.document_date)) : null;
    }, 300);
  },
  computed: {
    data() {
      return this.$store.getters.endowment;
    },
  },
};
</script>

<style lang="scss">
.fourth {
  .date-block {
    position: relative;
    width: fit-content;
    margin: 0 auto;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      display: block;
      height: 1px;
      width: 100%;
      background: var(--v-primary-base);
    }
  }
  .info-card > div {
    width: 30%;
  }
  .fild-text {
    white-space: pre-wrap;
  }
}
</style>
