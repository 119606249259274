<template>
  <section class="first">
    <v-card flat class="mt-6 rounded-xl px-7 pt-6 pb-10">
      <v-expansion-panels flat tile v-model="type">
        <v-expansion-panel>
          <v-expansion-panel-header
            class="black--text font-weight-bold text-h5 pa-0 flex-row-reverse justify-end"
          >
            <template v-slot:actions>
              <v-icon color="primary">mdi-chevron-down</v-icon>
            </template>
            {{ $t("waqf.step1")[0] }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-btn
              @click="data.type == i.type ? null : openModal(i.type)"
              height="58"
              class="btn-shadow radius10 px-5"
              :class="[
                data.type == i.type ? 'primary' : 'gray',
                j ? 'ms-4' : '',
              ]"
              v-for="(i, j) in typesList"
              :key="i.type"
            >
              <span class="text-body-1 white--text">{{ i.title }}</span>
            </v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <v-card flat class="mt-6 rounded-xl px-7 pt-6 pb-10">
      <v-expansion-panels flat tile v-model="date">
        <v-expansion-panel>
          <v-expansion-panel-header
            class="black--text font-weight-bold text-h5 pa-0 flex-row-reverse justify-end"
          >
            <template v-slot:actions>
              <v-icon color="primary">mdi-chevron-down</v-icon>
            </template>
            {{ $t("waqf.step1")[1] }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="black--text text-start">
              {{ $t("input.hijriDate") }}
            </div>
            <v-menu
              v-model="modalDate"
              :close-on-content-click="false"
              fixed
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="data.document_date"
                  :error-messages="dateErrors"
                  :placeholder="$t('input.hijriDate')"
                  outlined
                  required
                  color="secondary"
                  v-bind="attrs"
                  v-on="on"
                >
                  <template v-slot:append>
                    <img width="24" height="24" :src="getIcon('calendar')" />
                  </template>
                </v-text-field>
              </template>
              <VHijriDatePicker
                v-model="data.document_date"
                no-title
                color="primary"
                :active-picker.sync="activePicker"
                :locale="$root.$i18n.locale === 'en' ? 'en' : 'ar'"
                :min="today"
                @input="modalDate = false"
              />
            </v-menu>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <v-card flat class="mt-6 rounded-xl px-7 pt-6 pb-10">
      <v-expansion-panels flat tile v-model="introduction">
        <v-expansion-panel>
          <v-expansion-panel-header
            id="prefaceTitle"
            class="black--text font-weight-bold text-h5 pa-0 flex-row-reverse justify-end"
          >
            <template v-slot:actions>
              <v-icon color="primary">mdi-chevron-down</v-icon>
            </template>
            <v-btn
              @click.stop="data.introduction_text = template.introduction_text"
              height="48"
              :max-width="$vuetify.rtl ? 280 : 242"
              class="primary btn-shadow radius10 ms-auto"
            >
              <img src="@/assets/icon/reset.svg" alt="reset" />
              <span class="f15 ms-3">{{ $t("btn.resetDefault") }}</span>
            </v-btn>
            {{ $t("waqf.step1")[2] }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-textarea
              v-model="data.introduction_text"
              :error-messages="introductionErrors"
              persistent-hint
              :hint="$t('waqf.hint')"
              outlined
              required
              color="secondary"
              auto-grow
              row-height="30"
              rows="7"
            ></v-textarea>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <v-card flat class="mt-6 rounded-xl px-7 pt-6 pb-10">
      <v-expansion-panels flat tile v-model="owner">
        <v-expansion-panel>
          <v-expansion-panel-header
            class="black--text font-weight-bold text-h5 pa-0 flex-row-reverse justify-end"
          >
            <template v-slot:actions>
              <v-icon color="primary">mdi-chevron-down</v-icon>
            </template>
            {{ $t("waqf.step1")[3] }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="d-flex flex-wrap" style="max-width: 900px">
              <div class="width100 v-input d-block me-5">
                <div class="black--text text-start">
                  {{ $t("input.fullName") }}
                </div>
                <v-text-field
                  v-model="data.owner.full_name"
                  :placeholder="$t('input.fullName')"
                  outlined
                  required
                  color="secondary"
                  readonly
                ></v-text-field>
              </div>
              <div class="width100 v-input d-block ms-5">
                <div class="black--text text-start">
                  {{ $t("input.idNumber") }}
                </div>
                <v-text-field
                  v-model="data.owner.id_number"
                  :placeholder="$t('input.idNumber')"
                  outlined
                  required
                  color="secondary"
                  readonly
                ></v-text-field>
              </div>
              <div class="width100 v-input d-block me-5">
                <div class="black--text text-start">
                  {{ $t("input.birthday") }}
                </div>
                <v-text-field
                  v-model="data.owner.date_of_birth"
                  :placeholder="$t('input.birthday')"
                  outlined
                  required
                  color="secondary"
                  readonly
                >
                  <template v-slot:append>
                    <img width="24" height="24" :src="getIcon('calendar')" />
                  </template>
                </v-text-field>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <v-card flat class="mt-6 rounded-xl px-7 pt-6 pb-10">
      <Liquidity />
    </v-card>
    <v-card
      flat
      class="mt-6 rounded-xl px-10 pt-6 pb-10"
      v-for="(i, k) in data.steps[0].sections"
      :key="i.id"
    >
      <DocumentItem :i="i" :k="k" />
    </v-card>
    <v-dialog v-model="modal" max-width="675">
      <v-card flat outlined class="py-10 px-5 radius24">
        <v-btn class="btn-close" icon @click="modal = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div style="max-width: 370px" class="mx-auto text-center">
          <div class="arabic-black f24 black--text">
            {{ $t("waqf.changeType.title") }}
          </div>
          <div class="gray--text mt-4">
            {{ $t("waqf.changeType.description") }}
          </div>
          <div class="mt-10 d-flex justify-center">
            <v-btn
              @click="modal = false"
              height="64"
              width="167"
              outlined
              color="primary"
              class="radius10 me-3"
            >
              <span class="f15 font-weight-bold">{{ $t("btn.cancel") }}</span>
            </v-btn>
            <v-btn
              @click="changeType"
              depressed
              height="64"
              width="167"
              class="primary radius10 ms-3"
            >
              <span class="font-weight-bold text-body-1">
                {{ $t("btn.change") }}
              </span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { getHijhri, subtractYears } from "@/helpers/years";
import Liquidity from "@/components/waqf/step1/Liquidity.vue";
import DocumentItem from "@/components/waqf/DocumentItem.vue";

export default {
  components: { Liquidity, DocumentItem },
  data() {
    return {
      modal: false,
      typeValue: "",
      type: 0,
      date: 0,
      introduction: 0,
      owner: 0,
      modalDate: false,
      activePicker: "YEAR",
      minBirth: subtractYears(),
    };
  },
  methods: {
    openModal(i) {
      this.typeValue = i;
      this.$nextTick(() => {
        this.modal = true;
      });
    },
    getIcon(code) {
      return require(`@/assets/icon/${code}.svg`);
    },
    async changeType() {
      await this.$store.dispatch("createEndowment", { type: this.typeValue });
    },
  },
  watch: {
    introductionErrors(val) {
      if (val.length) {
        this.$route.query.step != 1
          ? this.$router.push("/endowment/waqf?step=1")
          : "";
        this.$nextTick(() => {
          document.getElementById("prefaceTitle").scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        });
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.endowment;
    },
    typesList() {
      return this.$store.getters.endowmentTypes;
    },
    error() {
      return this.$store.getters.error;
    },
    today() {
      return getHijhri(new Date());
    },
    dateErrors() {
      const errors = [];
      this.error.find((item) => item == "document_date__required") &&
        errors.push(this.$t("inputError.documentDateRequired"));
      this.error.find((item) => item == "document_date__invalid") &&
        errors.push(this.$t("inputError.documentDateInvalid"));
      return errors;
    },
    introductionErrors() {
      const errors = [];
      this.error.find((item) => item == "introduction_text__required") &&
        errors.push(this.$t("inputError.introductionTextRequired"));
      this.error.find((item) => item == "introduction_text__invalid") &&
        errors.push(this.$t("inputError.introductionTextInvalid"));
      return errors;
    },
    textErrors() {
      const errors = [];
      this.error.find((item) => item == "text__required") &&
        errors.push(this.$t("inputError.textRequired"));
      this.error.find((item) => item == "text__invalid") &&
        errors.push(this.$t("inputError.textInvalid"));
      return errors;
    },
  },
};
</script>
