import { render, staticRenderFns } from "./EndowmentDoc.vue?vue&type=template&id=e6c18c3a&"
import script from "./EndowmentDoc.vue?vue&type=script&lang=js&"
export * from "./EndowmentDoc.vue?vue&type=script&lang=js&"
import style0 from "./EndowmentDoc.vue?vue&type=style&index=0&id=e6c18c3a&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports