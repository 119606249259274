<template>
  <v-expansion-panels flat tile v-model="panel">
    <v-expansion-panel v-if="i.type == 'text'">
      <v-expansion-panel-header class="black--text font-weight-bold text-h5 pa-0 flex-row-reverse justify-end">
        <template v-slot:actions>
          <v-icon color="primary">mdi-chevron-down</v-icon>
        </template>
        <v-btn
          @click.stop="i.text = i.text_template"
          height="48"
          :max-width="$vuetify.rtl ? 280 : 242"
          class="primary btn-shadow radius10 ms-auto"
        >
          <img src="@/assets/icon/reset.svg" alt="reset" />
          <span class="f15 ms-3">{{ $t("btn.resetDefault") }}</span>
        </v-btn>
        {{ i.title }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-textarea
          v-model="i.text"
          :error-messages="textErrors"
          @change="updateItem(i, i)"
          persistent-hint
          :hint="$t('waqf.hint')"
          outlined
          required
          color="secondary"
          auto-grow
          row-height="30"
          rows="7"
        ></v-textarea>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel v-if="i.type == 'people'">
      <v-expansion-panel-header class="black--text font-weight-bold text-h5 pa-0 flex-row-reverse justify-end">
        <template v-slot:actions>
          <v-icon color="primary">mdi-chevron-down</v-icon>
        </template>
        {{ i.title }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div class="d-flex flex-wrap" v-for="(l, j) in i.items" :key="j + 'w'">
          <div class="width100 v-input d-block me-5">
            <div class="black--text text-start">{{ $t("input.fullName") }}</div>
            <v-text-field
              v-model="l.full_name"
              @change="l.id ? updateItem(l, i) : null"
              :placeholder="$t('input.fullName')"
              :error-messages="fullnameErrors"
              outlined
              required
              color="secondary"
            ></v-text-field>
          </div>
          <div class="width100 v-input d-block ms-5">
            <div class="black--text text-start">{{ $t("input.idNumber") }}</div>
            <v-text-field
              v-model="l.id_number"
              @change="l.id ? updateItem(l, i) : null"
              :placeholder="$t('input.idNumber')"
              :error-messages="idErrors"
              outlined
              required
              color="secondary"
              :disabled="!l.full_name"
              maxlength="10"
            ></v-text-field>
          </div>
          <v-btn
            v-if="l.id"
            @click="removeItem(l, i.id)"
            height="48"
            min-width="134"
            class="error btn-shadow radius10 mt-7 ms-10"
          >
            <img src="@/assets/icon/minus.svg" alt="minus" />
            <span class="f15 ms-3">{{ $t("btn.delete") }}</span>
          </v-btn>
          <v-card
            v-else
            class="mt-7 ms-10"
            width="134"
            height="48"
            tile
            flat
            color="transparent"
          ></v-card>
          <div class="width100 v-input d-block me-5">
            <div class="black--text text-start">{{ $t("input.birthday") }}</div>
            <v-menu
              :ref="`menu${k}`"
              :close-on-content-click="false"
              fixed
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="l.date_of_birth"
                  :error-messages="birthdayErrors"
                  :placeholder="$t('input.birthday')"
                  outlined
                  required
                  readonly
                  :disabled="!l.id_number"
                  color="secondary"
                  v-bind="attrs"
                  v-on="on"
                >
                  <template v-slot:append>
                    <img
                      width="24"
                      height="24"
                      :src="getIcon('calendar')"
                    />
                  </template>
                </v-text-field>
              </template>
              <VHijriDatePicker
                v-model="l.date_of_birth"
                no-title
                color="primary"
                :active-picker.sync="activePicker"
                :locale="$root.$i18n.locale === 'en' ? 'en' : 'ar'"
                :max="minBirth"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="
                    (l.date_of_birth = l.date_of_birth_old),
                      $refs.witnessMenu[j].save(l.date_of_birth_old)
                  "
                >
                  {{ $t("btn.cancel") }}
                </v-btn>
                <v-btn
                  @click="
                    l.id ? updateItem(l, i) : addItem(l, i),
                      $refs[`menu${k}`][j].save(l.date_of_birth)
                  "
                  text
                  color="primary"
                >
                  {{ $t("btn.ok") }}
                </v-btn>
              </VHijriDatePicker>
            </v-menu>
          </div>
        </div>
        <v-btn
          :disabled="!!i.items.find((i) => i.id == undefined)"
          v-if="i.list_items_max > i.items.length"
          @click="i.items.push({ full_name: null, id_number: null, date_of_birth: null, date_of_birth_old: null })"
          height="48"
          min-width="160"
          class="primary btn-shadow radius10"
        >
          <img src="@/assets/icon/plus.svg" alt="plus" />
          <span class="f15 ms-3">{{ $t("btn.addMore") }}</span>
        </v-btn>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel v-if="i.type == 'distributions'">
      <v-expansion-panel-header class="black--text font-weight-bold text-h5 pa-0 flex-row-reverse justify-end">
        <template v-slot:actions>
          <v-icon color="primary">mdi-chevron-down</v-icon>
        </template>
        <v-btn
          @click.stop="i.text = i.text_template"
          height="48"
          :max-width="$vuetify.rtl ? 280 : 242"
          class="primary btn-shadow radius10 ms-auto"
        >
          <img src="@/assets/icon/reset.svg" alt="reset" />
          <span class="f15 ms-3">{{ $t("btn.resetDefault") }}</span>
        </v-btn>
        {{ i.title }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-textarea
          v-model="i.text"
          :error-messages="textErrors"
          persistent-hint
          :hint="$t('waqf.hint')"
          @change="updateItem(i, {  type: 'text', id: i.id })"
          outlined
          required
          color="secondary"
          auto-grow
          row-height="30"
          rows="3"
        ></v-textarea>
        <div class="d-flex flex-wrap" v-for="(l, j) in i.items" :key="j + 'q'">
          <div class="width100 v-input d-block me-5">
            <div class="black--text text-start">
              {{ $t("input.addressEndowmentBank") }}
            </div>
            <div class="gray--text text-start text-body-2 mb-2">
              {{ $t("input.addressEndowmentBankExample") }}
            </div>
            <v-text-field
              v-model="l.beneficiary"
              :placeholder="$t('input.addressEndowmentBankPla')"
              @change="l.id ? updateItem(l, i) : null"
              :error-messages="beneficiaryErrors"
              outlined
              required
              color="secondary"
            ></v-text-field>
          </div>
          <div class="width100 v-input d-block ms-5">
            <div class="black--text text-start">{{ $t("input.bankDetail") }}</div>
            <div class="gray--text text-start text-body-2 mb-2">
              {{ $t("input.bankDetailExample") }}
            </div>
            <v-text-field
              v-model="l.details"
              :placeholder="$t('input.bankDetailPla')"
              @change="l.id ? updateItem(l, i) : addItem(l, i)"
              :error-messages="detailsErrors"
              outlined
              required
              :disabled="!l.beneficiary"
              color="secondary"
            ></v-text-field>
          </div>
          <v-btn
            @click="removeItem(l, i.id)"
            v-if="l.id"
            height="48"
            min-width="134"
            class="error btn-shadow radius10 ms-10 mt-14"
          >
            <img src="@/assets/icon/minus.svg" alt="minus" />
            <span class="f15 ms-3">{{ $t("btn.delete") }}</span>
          </v-btn>
        </div>
        <v-btn
          :disabled="!!i.items.find((i) => i.id == undefined)"
          v-if="i.list_items_max > i.items.length"
          @click="i.items.push({ beneficiary: null, details: null })"
          height="48"
          min-width="160"
          class="primary btn-shadow radius10"
        >
          <img src="@/assets/icon/plus.svg" alt="plus" />
          <span class="f15 ms-3">{{ $t("btn.addMore") }}</span>
        </v-btn>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel v-if="i.type == 'list'">
      <v-expansion-panel-header class="black--text font-weight-bold text-h5 pa-0 flex-row-reverse justify-end">
        <template v-slot:actions>
          <v-icon color="primary">mdi-chevron-down</v-icon>
        </template>
        <v-btn
          v-if="i.text_enabled"
          @click.stop="i.text = i.text_template"
          height="48"
          :max-width="$vuetify.rtl ? 280 : 242"
          class="primary btn-shadow radius10 ms-auto"
        >
          <img src="@/assets/icon/reset.svg" alt="reset" />
          <span class="f15 ms-3">{{ $t("btn.resetDefault") }}</span>
        </v-btn>
        {{ i.title }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-textarea
          v-if="i.text_enabled"
          v-model="i.text"
          :error-messages="textErrors"
          persistent-hint
          :hint="$t('waqf.hint')"
          outlined
          required
          @change="updateItem(i, {  type: 'text', id: i.id })"
          color="secondary"
          auto-grow
          row-height="30"
          rows="3"
        ></v-textarea>
        <div class="d-flex flex-wrap" v-for="(l, j) in i.items" :key="j + 'l'">
          <v-textarea
            v-model="l.text"
            :error-messages="textErrors"
            @change="l.id ? updateItem(l, i) : addItem(l, i)"
            outlined
            required
            color="secondary"
            auto-grow
            row-height="30"
            rows="3"
          ></v-textarea>
          <v-btn
            @click="removeItem(l, i.id)"
            v-if="l.id"
            height="48"
            min-width="134"
            class="error btn-shadow radius10 ms-10"
          >
            <img src="@/assets/icon/minus.svg" alt="minus" />
            <span class="f15 ms-3">{{ $t("btn.delete") }}</span>
          </v-btn>
        </div>
        <v-btn
          :disabled="!!i.items.find((i) => i.id == undefined)"
          v-if="i.list_items_max > i.items.length"
          @click="i.items.push({ text: null })"
          height="48"
          min-width="160"
          class="primary btn-shadow radius10"
        >
          <img src="@/assets/icon/plus.svg" alt="plus" />
          <span class="f15 ms-3">{{ $t("btn.addMore") }}</span>
        </v-btn>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { subtractYears } from "@/helpers/years";

export default {
  props: {
    i: Object,
    k: Number,
  },
  data() {
    return {
      panel: 0,
      activePicker: "YEAR",
      minBirth: subtractYears(),
    };
  },
  methods: {
    getIcon(code) {
      return require(`@/assets/icon/${code}.svg`);
    },
    async updateItem(i, item) {
      this.$store.dispatch("setError", []);
      const data = new Object();
      if (item.type == 'people') {
        data.id_number = i.id_number;
        data.full_name = i.full_name;
        i.date_of_birth
          ? (data.date_of_birth = i.date_of_birth.split("-").join("/"))
          : null;
      } else if (item.type == 'distributions') {
        data.beneficiary = i.beneficiary;
        data.details = i.details;
      } else if (item.type == 'list') {
        data.text = i.text;
      } else if (item.type == 'text') {
        data.text = i.text;
      } else {
        Object.assign(data, i);
      }
      await this.$store
        .dispatch(
          item.type == 'text' ? "updateSectionEndowmentV2" : "updateSectionItemEndowmentV2",
          item.type == 'text' ? { id: item.id, data: data } : { id: item.id, itemId: i.id, data: data }
        )
        .catch((e) => {
          this.$store.dispatch("setError", e.response.data.error);
        });
    },
    async addItem(i, item) {
      this.$store.dispatch("setError", []);
      const data = new Object();
      if (item.type == 'people') {
        data.id_number = i.id_number;
        data.full_name = i.full_name;
        i.date_of_birth
          ? (data.date_of_birth = i.date_of_birth.split("-").join("/"))
          : null;
      } else if (item.type == 'distributions') {
        data.beneficiary = i.beneficiary;
        data.details = i.details;
      } else if (item.type == 'list') {
        data.text = i.text;
      } else if (item.type == 'text') {
        data.text = i.text;
      } else {
        Object.assign(data, i);
      }
      await this.$store.dispatch("addSectionItemEndowmentV2", { data: data, id: item.id }).catch((e) => {
        this.$store.dispatch("setError", e.response.data.error);
      });
    },
    async removeItem(i, id) {
      this.$store.dispatch("setError", []);
      await this.$store
        .dispatch("removeSectionItemEndowmentV2", { id: id, itemId: i.id })
        .catch((e) => {
          this.$store.dispatch("setError", e.response.data.error);
        });
    },
  },
  computed: {
    error() {
      return this.$store.getters.error;
    },
    fullnameErrors() {
      const errors = [];
      this.error.find((item) => item == "full_name__required") &&
        errors.push(this.$t("inputError.birthdayRequired"));
      this.error.find((item) => item == "full_name__invalid") &&
        errors.push(this.$t("inputError.birthdayInvalid"));
      return errors;
    },
    idErrors() {
      const errors = [];
      this.error.find((item) => item == "id_number__required") &&
        errors.push(this.$t("inputError.idRequired"));
      this.error.find((item) => item == "id_number__invalid") &&
        errors.push(this.$t("inputError.idInvalid"));
      return errors;
    },
    birthdayErrors() {
      const errors = [];
      this.error.find((item) => item == "date_of_birth__required") &&
        errors.push(this.$t("inputError.birthdayRequired"));
      this.error.find((item) => item == "date_of_birth__invalid") &&
        errors.push(this.$t("inputError.birthdayInvalid"));
      return errors;
    },
    textErrors() {
      const errors = [];
      this.error.find((item) => item == "text__required") &&
        errors.push(this.$t("inputError.textRequired"));
      this.error.find((item) => item == "text__invalid") &&
        errors.push(this.$t("inputError.textInvalid"));
      return errors;
    },
    beneficiaryErrors() {
      const errors = [];
      this.error.find((item) => item == "beneficiary__required") &&
        errors.push(this.$t("inputError.beneficiaryRequired"));
      this.error.find((item) => item == "beneficiary__invalid") &&
        errors.push(this.$t("inputError.beneficiaryInvalid"));
      return errors;
    },
    detailsErrors() {
      const errors = [];
      this.error.find((item) => item == "details__required") &&
        errors.push(this.$t("inputError.detailsRequired"));
      this.error.find((item) => item == "details__invalid") &&
        errors.push(this.$t("inputError.detailsInvalid"));
      return errors;
    },
  },
};
</script>
