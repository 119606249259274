<template>
  <section>
    <v-card flat class="mt-6 rounded-xl px-10 pt-6 pb-10" v-for="(i, k) in data.steps[$route.query.step - 1].sections" :key="i.id">
      <DocumentItem :i="i" :k="k" />
    </v-card>
  </section>
</template>

<script>
import DocumentItem from '@/components/waqf/DocumentItem.vue';

export default {
  components: { DocumentItem },
  mounted() {
    this.$store.commit('setLiquidity', this.data.liquidity);
  },
  computed: {
    data() {
      return this.$store.getters.endowment;
    },
  },
};
</script>
