<template>
  <div class="waqf" v-scroll="handleScroll">
    <div v-if="!data.status">
      <h1 class="black--text arabic-black f36 text-capitalize me-3">
        {{ $t("waqf.document") }}
      </h1>
      <v-card flat class="mt-6 rounded-xl px-7 pt-6 pb-10">
        <v-expansion-panels flat tile v-model="type">
          <v-expansion-panel>
            <v-expansion-panel-header
              class="black--text font-weight-bold text-h5 pa-0 flex-row-reverse justify-end"
            >
              <template v-slot:actions>
                <v-icon color="primary">mdi-chevron-down</v-icon>
              </template>
              {{ $t("waqf.step1")[0] }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-btn
                @click="data.type == i.type ? null : changeType(i.type)"
                height="58"
                class="btn-shadow radius10 px-5"
                :class="[
                  data.type == i.type ? 'primary' : 'gray',
                  j ? 'ms-4' : '',
                ]"
                v-for="(i, j) in typesList"
                :key="i.type"
              >
                <span class="text-body-1 white--text">{{ i.title }}</span>
              </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </div>
    <div v-else-if="data.status == 'draft'">
      <div class="d-flex">
        <ul class="d-flex align-center pa-0">
          <li
            class="d-flex align-center"
            :class="j ? 'ms-8' : ''"
            v-for="(i, j) in data.steps"
            :key="i.id"
          >
            <v-card
              flat
              width="32"
              height="32"
              :color="j + 1 <= $route.query.step ? 'primary' : 'gray'"
              class="rounded-circle white--text text-body-2 font-weight-bold text-center"
              style="line-height: 32px"
            >
              {{ j + 1 }}
            </v-card>
            <div
              class="ms-2 text-body-2"
              :class="
                j + 1 <= $route.query.step
                  ? 'primary--text font-weight-bold'
                  : 'gray--text'
              "
            >
              {{ i.title }}
            </div>
          </li>
          <li class="d-flex align-center ms-8">
            <v-card
              flat
              width="32"
              height="32"
              :color="
                data.steps.length + 1 <= $route.query.step ? 'primary' : 'gray'
              "
              class="rounded-circle white--text text-body-2 font-weight-bold text-center"
              style="line-height: 32px"
            >
              {{ data.steps.length + 1 }}
            </v-card>
            <div
              class="ms-2 text-body-2"
              :class="
                data.steps.length + 1 <= $route.query.step
                  ? 'primary--text font-weight-bold'
                  : 'gray--text'
              "
            >
              {{ $t("waqf.steps")[3] }}
            </div>
          </li>
        </ul>
        <v-spacer></v-spacer>
        <div>
          <v-btn
            @click="action('save')"
            height="48"
            min-width="160"
            class="primary btn-shadow radius10"
          >
            <img src="@/assets/icon/document.svg" alt="document" />
            <span class="f15 ps-3">{{ $t("btn.saveDraft") }}</span>
          </v-btn>
          <v-btn
            v-if="+$route.query.step != data.steps.length + 1"
            @click="action('preview')"
            height="48"
            min-width="144"
            class="black btn-shadow radius10 ms-4"
          >
            <img src="@/assets/icon/view.svg" alt="document" />
            <span class="f15 ps-3 white--text">{{ $t("btn.preview") }}</span>
          </v-btn>
          <v-btn
            v-else
            @click="$router.go(-1)"
            height="48"
            min-width="144"
            class="black btn-shadow radius10 ms-4"
          >
            <span class="f15 white--text">{{ $t("btn.back") }}</span>
          </v-btn>
        </div>
      </div>
      <div class="d-flex align-center mt-6">
        <h1 class="black--text arabic-black f36 text-capitalize">
          {{
            data.steps[$route.query.step - 1]
              ? data.steps[$route.query.step - 1].title || data.steps[0].title
              : $t("waqf.steps")[3]
          }}
        </h1>
        <!-- <v-spacer></v-spacer>
        <div class="secondary--text arabic-black f36">{{ progress[$route.query.step] || 0 }}%</div>
        <div class="ms-4 width100" style="max-width: 260px">
          <v-progress-linear background-color="gray lighten-3" color="secondary" :value="progress[$route.query.step]"></v-progress-linear>
          <div class="gray--text text--caption mt-1">{{ $t('waqf.progress') }}</div>
        </div> -->
      </div>
      <v-window v-model="step">
        <v-window-item
          :value="j + 1"
          v-for="(i, j) in data.steps"
          :key="i.id + 's'"
        >
          <StepOther v-if="j" />
          <Step1 v-else />
        </v-window-item>
        <v-window-item :value="data.steps.length + 1">
          <StepLast />
        </v-window-item>
      </v-window>
      <div
        class="text-center mt-10"
        v-if="data.steps.length + 1 != $route.query.step"
      >
        <v-btn
          @click="action('step')"
          height="48"
          min-width="144"
          class="primary btn-shadow radius10"
        >
          <span class="text-body-1 font-weight-bold">{{ $t("btn.next") }}</span>
        </v-btn>
      </div>
      <div class="text-center mt-10" v-else>
        <v-btn
          @click="create"
          height="48"
          min-width="144"
          class="primary btn-shadow radius10 me-2"
        >
          <span class="text-body-1 font-weight-bold">{{
            $t("btn.create")
          }}</span>
        </v-btn>
        <v-btn
          :href="`${link}/customer/api/v2/endowment/download?t=${token}&lang=${$i18n.locale}`"
          height="48"
          min-width="160"
          class="black btn-shadow radius10 ms-2"
        >
          <img src="@/assets/icon/download.svg" alt="download" />
          <span class="f15 white--text ms-3">{{ $t("btn.download") }}</span>
        </v-btn>
      </div>
    </div>
    <div v-else>
      <div class="d-flex align-center">
        <h1 class="black--text arabic-black f36 text-capitalize me-3">
          {{ $t("waqf.preview") }}
        </h1>
        <v-spacer></v-spacer>
        <v-btn
          :href="`${link}/customer/api/v2/endowment/download?t=${token}&lang=${$i18n.locale}`"
          height="48"
          min-width="160"
          class="black btn-shadow radius10"
        >
          <img src="@/assets/icon/download.svg" alt="download" />
          <span class="f15 white--text ms-3">{{ $t("btn.download") }}</span>
        </v-btn>
      </div>
      <StepLast />
    </div>
    <div v-if="scrollPage >= 400" class="arrow-start link" @click="scrollToTop">
      <img src="@/assets/icon/arrow.svg" alt="arrow-top" />
    </div>
  </div>
</template>

<script>
import Step1 from "@/components/waqf/Step1";
import StepOther from "@/components/waqf/StepOther";
import StepLast from "@/components/waqf/StepLast";

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  components: {
    Step1,
    StepOther,
    StepLast,
  },
  data() {
    return {
      link: window.origin,
      token: sessionStorage.getItem("accessToken"),
      step: +this.$route.query.step || 1,
      type: 0,
      scrollPage: 0,
    };
  },
  mounted() {
    this.$store.dispatch("getEndowmentV2");
    this.$store.dispatch("getEndowmentTypes");
  },
  methods: {
    scrollToTop() {
      document.getElementById("start").scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
    handleScroll() {
      this.scrollPage = window.pageYOffset;
    },
    async changeType(i) {
      await this.$store.dispatch("createEndowment", { type: i });
    },
    async action(i) {
      this.$store.dispatch("setError", []);
      const res = await this.$store.dispatch("updateLiquidity");
      if (!res) {
        return;
      }
      const data = new Object();
      // data.type = this.data.type;
      this.data.document_date
        ? (data.document_date = this.data.document_date.split("-").join("/"))
        : null;
      data.introduction_text = this.data.introduction_text;
      await this.$store
        .dispatch("updateEndowmentV2", data)
        .then(() => {
          i == "preview"
            ? this.$router.push(`/endowment/waqf?step=${this.data.steps.length + 1}`)
            : this.$router.push(`/endowment/waqf?step=${+this.$route.query.step + 1}`);
        })
        .catch((e) => {
          this.$store.dispatch("setError", e.response.data.error);
        });
    },
    async create() {
      this.$store.dispatch("setError", []);
      await this.$store
        .dispatch("actionEndowmentV2", { action: "filled" })
        .then(() => {
          this.$store.dispatch("getProfile");
          this.$router.push("/");
        })
        .catch((e) => {
          this.$store.dispatch("setError", e.response.data.error);
          // setTimeout(() => {
          //   if (this.error.find((item) => item == "witnesses__empty")) {
          //     this.error.push("full_name__required");
          //     this.error.push("id_number__required");
          //     this.error.push("date_of_birth__required");
          //     this.$router.push(`/endowment/waqf?step=1#${this.error[0]}`);
          //   } else if (
          //     this.error.find((item) => item == "admins__empty") ||
          //     this.error.find((item) => item == "admin_responsibilities__empty")
          //   ) {
          //     if (this.error.find((item) => item == "admins__empty")) {
          //       this.error.push("full_name__required");
          //       this.error.push("id_number__required");
          //       this.error.push("date_of_birth__required");
          //     } else {
          //       this.error.push("responsibility_text__required");
          //     }
          //     this.$router.push(`/endowment/waqf?step=2#${this.error[0]}`);
          //   } else if (
          //     this.error.find(
          //       (item) => item == "revenue_spending_methods__empty"
          //     ) ||
          //     this.error.find((item) => item == "spendings__empty")
          //   ) {
          //     if (
          //       this.error.find(
          //         (item) => item == "revenue_spending_methods__empty"
          //       )
          //     ) {
          //       this.error.push("method_text__required");
          //     } else {
          //       this.error.push("beneficiary__required");
          //       this.error.push("details__required");
          //     }
          //     this.$router.push(`/endowment/waqf?step=3#${this.error[0]}`);
          //   }
          // }, 300);
        });
    },
  },
  watch: {
    route() {
      this.step = +this.$route.query.step || 1;
    },
  },
  computed: {
    data() {
      return this.$store.getters.endowment;
    },
    typesList() {
      return this.$store.getters.endowmentTypes;
    },
    error() {
      return this.$store.getters.error;
    },
    progress() {
      return {
        1: 20,
        2: 60,
        3: 80,
        4: 100,
      };
    },
    route() {
      return this.$route.query.step;
    },
  },
  destroyed() {
    this.$store.dispatch("setEndowment");
    this.$store.dispatch("setError", []);
  },
};
</script>

<style lang="scss">
.waqf {
  .v-expansion-panel-header {
    gap: 24px;
  }
  .v-expansion-panel--active > .v-expansion-panel-header {
    min-height: initial;
  }
  .v-expansion-panel-header__icon {
    margin: 0 !important;
  }
  .v-expansion-panel-content__wrap {
    padding: 24px 0 0 48px;
  }
  .v-input {
    max-width: 398px;
  }
  .v-textarea {
    max-width: 70%;
  }
}
</style>
