<template>
  <v-expansion-panels flat tile v-model="liquidity">
    <v-expansion-panel>
      <v-expansion-panel-header
        id="liquidityTitle"
        class="black--text font-weight-bold text-h5 pa-0 flex-row-reverse justify-end"
      >
        <template v-slot:actions>
          <v-icon color="primary">mdi-chevron-down</v-icon>
        </template>
        {{ $t("waqf.step1")[4] }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div class="d-flex flex-column" style="max-width: 900px">
          <div class="width100 v-input d-block me-5">
            <div class="black--text text-start">{{ $t("input.endowmentType") }}</div>
            <v-select
              :items="typesList"
              outlined
              :value="selectedType"
              @input="selectedTypeChange"
              :placeholder="$t('input.select')"
            ></v-select>
          </div>
          <v-fab-transition>
            <div class="d-flex flex-wrap">
              <div
                class="width100 v-input d-block me-10"
                v-for="item in activeItems"
                :key="item"
              >
                <div class="black--text text-start">{{ $t(`input.${item}`) }}</div>
                <v-text-field
                  :placeholder="$t(`input.${item}`)"
                  @input="(v) => changeItem(item, v)"
                  :value="liquidityData[item]"
                  outlined
                  color="secondary"
                  :error-messages="getErrors(item)"
                ></v-text-field>
              </div>
            </div>
          </v-fab-transition>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { mapGetters, mapState } from "vuex";

export default {
  data() {
    return {
      liquidity: 0,
      types: [
        "real_estate",
        "current_account",
        "gold",
        "silver",
        "company",
        "stock_portfolio",
      ],
      selectedType: null,
      realEstateList: [
        "land_number",
        "block",
        "district",
        "city",
        "deed_number",
        "deed_history",
        "total_space",
      ],
      accountItems: ["account_number", "bank_name", "ratio"],
      goldItems: ["gold_karat", "number_of_ounces", "weight_grams"],
      silverItems: ["weight_grams"],
      companyItems: [
        "company_name",
        "cr_number",
        "capital",
        "company_hq",
        "activity",
        "deed_number",
        "ratio",
      ],
      portfolioItems: ["account_number", "bank_name", "ratio"],
    };
  },
  mounted() {
    this.$store.commit("setLiquidity", this.liquidityDataGetter);
    this.selectedType = this.liquidityDataGetter.type;
  },
  methods: {
    changeItem(name, value) {
      this.$store.commit("changeLiquidityField", { name, value });
    },
    selectedTypeChange(value) {
      this.selectedType = value;
      this.$store.commit("resetLiquidityData", value);
    },
    getErrors(field) {
      if (
        this.errors.length &&
        this.errors.some((item) => item.includes(field))
      ) {
        this.$route.query.step != 1
          ? this.$router.push("/endowment/waqf?step=1")
          : "";
        this.$nextTick(() => {
          document.getElementById("liquidityTitle").scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        });
        return this.liquidityErrors;
      } else {
        return [];
      }
    },
  },
  watch: {
    endowment(newValue) {
      this.$store.commit("setLiquidity", newValue.liquidity);
      this.selectedType = newValue.liquidity.type;
    },
  },
  computed: {
    typesList() {
      return this.types.map((item) => ({
        text: this.$t(`liquidityTypes.${item}`),
        value: item,
      }));
    },
    activeItems() {
      switch (this.selectedType) {
        case "real_estate":
          return this.realEstateList;
        case "current_account":
          return this.accountItems;
        case "gold":
          return this.goldItems;
        case "silver":
          return this.silverItems;
        case "company":
          return this.companyItems;
        case "stock_portfolio":
          return this.portfolioItems;
        default:
          return [];
      }
    },
    ...mapState({
      endowment: (state) => state.endowment.endowment,
      liquidityData: (state) => state.liquidity.data,
      errors: (state) => state.liquidity.errors,
    }),
    ...mapGetters({
      liquidityErrors: "liquidityErrors",
    }),
    liquidityDataGetter() {
      return this.endowment.liquidity || {};
    },
  },
};
</script>
